<!--
 * @Descripttion:
 * @version:
 * @Author: hutian
 * @Date: 2021-01-13 15:32:40
 * @LastEditors: hutian
 * @LastEditTime: 2021-05-11 17:50:10
-->
<template>
  <a-card :bordered="false">
  <div>
    <a-row :gutter="8">
      <a-col :span="24">
        <div class="table-page-search-wrapper">
          <a-form layout="inline" :label-col="{xs:6}" :wrapper-col="{xs:18}">
            <a-row :gutter="8">
              <a-col :xxl="6" :xl="6" :md="8" :sm="12">
                <a-form-item label="字典名称">
                  <a-input placeholder="请输入" v-model="queryParam.title" allowClear/>
                </a-form-item>
              </a-col>
              <a-col :xxl="6" :xl="6" :md="8" :sm="12">
                <a-form-item label="字典编码">
                  <a-input placeholder="请输入" v-model="queryParam.code" allowClear/>
                </a-form-item>
              </a-col>
              <a-col :xxl="6" :xl="6" :md="8" :sm="12">
                <span class="table-page-search-submitButtons">
                  <a-button type="primary" @click="getList(0)">查询</a-button>
                  <a-button style="margin-left: 8px" @click="getList(1)">重置</a-button>
                </span>
              </a-col>
            </a-row>
          </a-form>
        </div>
      </a-col>
    </a-row>
    <div>
      <a-button type="primary" @click="toHanlder('add')">新增字典</a-button>
    </div>
    <a-table :rowKey="record=>record.id" :columns="columns" :data-source="dicList" bordered :pagination="false">
      <span slot="flagEnable" slot-scope="text">{{text?"启用":"停用"}}</span>
      <span slot="action" slot-scope="text, record">
        <a @click="toHanlder('edit',record)">编辑</a>
        <a-divider type="vertical"/>
        <a @click="toHanlder('detail',record)">
          <a-icon type="bars"/>字典详情</a>
        <a-divider type="vertical"/>
        <a @click="toHanlder('del',record)">删除</a>
      </span>
    </a-table>
    <edit-dict-modal ref="editDict" @reload="toSearch"></edit-dict-modal>
    <dic-detail-modal ref="dicDetail" @reload="toSearch"></dic-detail-modal>
  </div>
  </a-card>
</template>

<script>
  import {
    dicColumns
  } from "./components/columns.js"
  import editDictModal from "./components/EditDictModal.vue"
  import dicDetailModal from "./components/DictDetailModal.vue"
  import {
    getDictList
  } from '@/api/system'

  export default {
    data() {
      return {
        // 查询参数
        queryParam: {},
        dicList: [],
        recordData:'',
        parentDicList: [
          {
            id: 1,
            label: '通用字典'
          }
        ],
        columns: dicColumns
      }
    },
    components: {
      editDictModal,
      dicDetailModal
    },
    methods: {
      /**
       * 获取字典列表（包括父字典）
       */
      toSearch() {
        const that = this
        that.axios.get(`/api/base/system/dictionary/list`).then(res => {
          that.dicList = res.body
        }).catch(err => {
        })
      },

      getList(num) {
        const that = this
        if(num == 1) {
          this.queryParam = {}
        }
        const params = Object.assign({},this.queryParam)
        getDictList(params).then(res => {
          that.dicList = res.body
        }).catch(err => {
        })
      },
      /**
       * 编辑字典
       */
      toHanlder(handle, record) {
        this.recordData = record
        if (handle == 'add') {
          this.$refs.editDict.showEditDict(this.parentDicList, 'add')
        } else if (handle == 'edit') {
          this.$refs.editDict.showEditDict(this.parentDicList, 'edit', this.recordData);
        } else if (handle == 'del') {
          this.$confirm({
            title: '警告',
            content: `真的删除该字典吗?`,
            okType: 'danger',
            onOk: () => {
              this.axios.post(`/api/base/system/dictionary/del/${record.id}`).then(res => {
                this.$notification.success({message: res.message})
                this.toSearch()
              }).catch(err => {
              })
            }
          })
        } else if (handle == 'detail') {
          this.$refs.dicDetail.showEditDict(this.recordData);
        }
      }
    },
    // },
    created() {
      this.toSearch()
    }
  }
</script>

<style lang="less" scoped>
</style>
